
import * as types from './actionTypes';

export const getCustomer = (name,params) => ({type: types.CUSTOMER_GET, name, params });

export const customerSuccess = (name, payload) => ({type: types.CUSTOMER_SUCCESS,name, payload})

export const customerFail = (name, error) => ({ type: types.CUSTOMER_FAIL, name, error });

export const getMessages = (name, customer_id) => ({type:types.MESSAGES_GET,name, customer_id})

export const messagesSuccess = (name, payload) => ({type: types.MESSAGES_SUCCESS,name, payload})

export const messagesFail = (name, error) => ({ type: types.MESSAGES_FAIL, name, error });

export const messageAdd = (params) => ({type: types.MESSAGE_ADD, params});

export const getDbCustomer = (params) => ({type : types.DB_CUSTOMER_GET, params});

export const dbCustomerSuccess = (payload) => ({type : types.DB_CUSTOMER_SUCCESS, payload});

export const saveDbCustomer = (id, params) => ({type : types.DB_CUSTOMER_SAVE, id, params});

export const dbCustomerSaveSuccess = (payload) => ({type : types.DB_CUSTOMER_SAVE_SUCCESS, payload});

export const dbCustomerClose = () => ({type : types.DB_CUSTOMER_CLOSE });