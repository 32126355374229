import React from 'react';
import LoginPage from "./login/index";
import {Route} from "react-router-dom";

const CustomerList = React.lazy(() => import('./customers/index'));
const ManagerPage = React.lazy(() => import('./managers/index'));
const AdminManagers = React.lazy(() => import('./admin/manager/index'));
const MapReportPage = React.lazy(() => import('./geo/index'));
const ManagerOrderPage = React.lazy(()  => import('./managers/shipment/index'))
const CompareSalesPage = React.lazy(()  => import('./managers/compareSale/index'))
const AdminRegions = React.lazy((() => import('./admin/regions/index')))
const MessageLogPage = React.lazy((() => import('./messages/log/index')))
const InventoryImportPage = React.lazy((() => import('./inventory/import/index')))
const OrderList = React.lazy((() => import('./order/list/index')))
const CheckPrice = React.lazy((() => import('./order/checkprice/index')))
const FinanceCostsList = React.lazy((() => import('./finanace/costs/index')))
const SettingImportPage = React.lazy((() => import('./admin/setting/index')))


const routes = [
  { path: '/', exact: true, name: 'Home11' },
  { path: '/customer', name: 'История по клиентам', component: CustomerList},
  { path: '/manager', name: 'Статистика по менеджерам', component: ManagerPage},
  { path: '/manager-region', name: 'Статистика по регионам', component: MapReportPage},
  { path: '/manager-order', name: 'Заказы менеджеров', component: ManagerOrderPage},
  { path: '/compare-sales', name: 'Сравнение продаж', component: CompareSalesPage},
  { path:'/signin', name: 'login', component: LoginPage},
  { path:'/admin/managers', name: 'менеджеры', component: AdminManagers},
  { path:'/admin/regions', name: 'регионы', component: AdminRegions},
  { path: '/message_log' , name : '', component: MessageLogPage},
  { path: '/admin/supplier/upload', name: "загрузка товаров", component: InventoryImportPage},
  { path: '/admin/settings/import', name: "настройка загрузки товаров", component: SettingImportPage},
  { path: '/orders/:id', name: "Документы", component: OrderList},
  { path: '/checkprice/:id', name: "Документы", component: CheckPrice},
  { path: '/finance/costs', name: "Затраты", component: FinanceCostsList}


];

export default routes;
