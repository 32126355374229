import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './scss/style.scss';
import store from './app/store/index';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Router from "./app/routing";
import {Provider} from "react-redux";

import { icons } from './assets/icons'
React.icons = icons

ReactDOM.render(
    <BrowserRouter >
    <Provider store={store}>
            <Router />
    </Provider>
</BrowserRouter>
    ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
