
import * as types from '../constants/actionTypes';
import {ORDER_CHECK_PRICE_SUCCESS,ORDER_CHECK_PRICE_FAIL} from "../check_price/constants/actionTypes";
const initialState = {
    check_price :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ORDER_LIST_SUCCESS: {
            return {
                ...state,
                [action.orderType]: action.payload,
            };
        }
        case types.ORDER_LIST_FAIL: {
            return {
                ...state,
                orders: [],
                errors : {
                    error: action.error,
                },
            };
        }
        case ORDER_CHECK_PRICE_SUCCESS: {
            return {
                ...state,
                check_price: action.payload,
            };
        }
        case ORDER_CHECK_PRICE_FAIL: {
            return {
                ...state,
                check_price: [],
                errors : {
                    error: action.error,
                },
            };
        }
        default: return state;
    }
};
