import { all } from 'redux-saga/effects';
import { managerWatcher  } from './managerSaga';
import {managerRefresherWatcher, managerStatWatcher} from "./managerStatSaga";
import {managerOrderWatcher} from "./managerOrderSaga";
import {managerSaleWatcher} from "./managerSaleCompare";

function* managerSaga() {
    yield all([
        managerWatcher(),
        managerStatWatcher(),
        managerRefresherWatcher(),
        managerOrderWatcher(),
        managerSaleWatcher(),
    ]);
}

export default managerSaga;
