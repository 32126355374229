
import * as types from '../constants/actionTypes';

const initialState = {
    customers :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CUSTOMER_SUCCESS: {
            return {
                ...state,
                [action.name]: action.payload,
                message: [],
            };
        }
        case types.CUSTOMER_FAIL: {
            return {
                ...state,
                [action.name]: {
                    error: action.error,
                },
            };
        }
        case types.MESSAGES_SUCCESS:{
            if (action.payload === null ){
                return {
                        ...state,
                        [action.name]:[],
                }
            }
            return {
                ...state,
                [action.name]:action.payload,
            }
        }
        case types.MESSAGES_FAIL: {
            return {
                ...state,
                [action.name]: {
                    error: action.error,
                },
            };
        }
        case types.DB_CUSTOMER_SUCCESS:{
            return {
                ...state,
                db_customer: action.payload,
                db_customer_action :"LOAD",
            }
        }
        case types.DB_CUSTOMER_SAVE_SUCCESS:{
            return {
                ...state,
                db_customer: action.payload,
                db_customer_action :"SAVE",
            }
        }
        case types.DB_CUSTOMER_CLOSE :{
            return {
                ...state,
                db_customer: undefined,
                db_customer_action :"",
            }
        }
        default: return state;
    }
};
