import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { AUTH_TOKEN} from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';
import history from "../../../routing/history";


export function* refreshTokenWatcher() {
    yield takeEvery(AUTH_TOKEN, refreshTokenWorker);
}

function* refreshTokenWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/auth/refresh', 'post', {
            auth_token:action.token});
        yield put(actions.authSuccess('auth', payload.data.data));
        yield put(stopLoading());
        yield put(showMessage({
            message: 'You logged in',
            variant: 'success',
        }));
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.authFail('auth', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}

