import {
    takeEvery, put, call, select,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {MANAGER_STAT_GET, MANAGER_STAT_REFRESH} from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';
import {processManagerStat} from "./func";

export const getManagerStatSource = (state) => state.managerReducer.source;

export function* managerStatWatcher() {
    yield takeEvery(MANAGER_STAT_GET, managerStatWorker);
}

export function* managerRefresherWatcher() {
    yield takeEvery(MANAGER_STAT_REFRESH, managerRefreshed);
}

function* managerStatWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/manager/stat', 'post',action.payload);
        const response = processManagerStat(payload.data.data.manager_stat, action.date_range_type);

        yield put(actions.managerStatSuccess('manager_stat',{
            response : response ,
            source : payload.data.data.manager_stat
        }));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.managerStatFail('manager_stat', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}


function* managerRefreshed(action){
    let managers = yield select(getManagerStatSource);
    const response = processManagerStat(managers, action.date_range_type);
    yield put(actions.managerStatSuccess('manager_stat',
        {
            response : response ,
            source : managers
        }))
}