export const CUSTOMER_GET = '[CUSTOMER] GET';
export const CUSTOMER_SUCCESS = '[CUSTOMER] SUCCESS';
export const CUSTOMER_FAIL = '[CUSTOMER] FAIL';
export const MESSAGES_GET = '[MESSAGES] GET';
export const MESSAGES_SUCCESS = '[MESSAGES] SUCCESS';
export const MESSAGES_FAIL = '[MESSAGES] FAIL';
export const MESSAGE_ADD  = '[MESSAGES] ADD';

export const DB_CUSTOMER_GET = '[CUSTOMER] DB GET'
export const DB_CUSTOMER_SAVE  = '[CUSTOMER] DB_SAVE'
export const DB_CUSTOMER_SUCCESS = '[CUSTOMER] DB SUCCESS'
export const DB_CUSTOMER_SAVE_SUCCESS = '[CUSTOMER] DB SAVE SUCCESS'
export const DB_CUSTOMER_CLOSE = '[CUSTOMER] DB SAVE CLOSE'

