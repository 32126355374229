import {call, put, takeEvery} from "redux-saga/effects";
import {MANAGER_ORDER_GET} from "../constants/actionTypes";
import {showMessage, startLoading, stopLoading} from "../../theme/constants/actionConstants";
import myAxios from "../../constants/myAxios";
import * as actions from "../constants/actionConstants";

export function* managerOrderWatcher() {
    yield takeEvery(MANAGER_ORDER_GET, managerOrderWorker);
}

function* managerOrderWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/manager/shipment', 'get',action.payload);
        let result = []
        if (payload.data.data != null ) {
            result = payload.data.data.map((row, ndx) => {
                row.key = row.manager_id
                return row
            })
        }
        yield put(actions.managerOrderSuccess('managers_order', result));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        console.log(error)
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.managerFail('managers_order', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
