import { all } from 'redux-saga/effects';
import {costListWatcher} from "./orderSaga";

function* financeCostsSaga() {
    yield all([
        costListWatcher(),
    ]);
}

export default financeCostsSaga;
