import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { MANAGER_SALE_COMPARE_GET } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';


export function* managerSaleWatcher() {
    yield takeEvery(MANAGER_SALE_COMPARE_GET, managerSaleWorker);
}

function* managerSaleWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/manager/compare_sale', 'get',action.payload);
        let result = []
        if (payload.data.data != null ){
            result = payload.data.data
        }
        yield put(actions.managerSaleCompareSuccess('managers', result));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.managerFail('managers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
