import React, {useEffect, useState} from 'react'
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CForm,CTextarea,CCol,CLabel,
    CFormGroup
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {createManager} from "../../../store/admin/constants/actionConstants";
import {useDispatch} from "react-redux";
import {messageAdd} from "../../../store/customer/constants/actionConstants";


const AddMessage = (props) =>{
    const dispatch = useDispatch();
    const [message,setMessage] = useState("");

    const saveUser = () => {
        const params = {
            message: message,
	        customer_id:props.customer_id,
        };
        dispatch(messageAdd(params));
        closeModal();
    }
    useEffect(()=>{
        if (props.show) {
            setMessage("")
        }
    },[props.show]);

    const closeModal = () =>{
        setMessage("");
      props.closeevent(false);
    };

    return(
        <CModal
            show={props.show}
            onClose={closeModal}
            closeOnBackdrop={false}
        >
            <CModalHeader closeButton>
                <CModalTitle>{props.customer_name}</CModalTitle>
            </CModalHeader>
            <CModalBody>
            <CForm action="" method="post">
            <CFormGroup row>
                <CCol md="3">
                    <CLabel htmlFor="textarea-input">Textarea</CLabel>
                </CCol>
                <CCol xs="12" md="9">
                    <CTextarea
                        name="textarea-input"
                        id="textarea-input"
                        rows="9"
                        value={message}
                        onChange={(e)=> {setMessage(e.target.value)}}
                        placeholder="Content..."
                    />
                </CCol>
            </CFormGroup>
            </CForm>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary"
                         onClick={saveUser} >Сохранить</CButton>{' '}
                <CButton
                    color="secondary"
                    onClick={() => {
                        closeModal();
                    }}
                >Cancel</CButton>
            </CModalFooter>
        </CModal>

    )
}
export default AddMessage;