import React, {useEffect, useState} from 'react'
import history from '../../../routing/history'
import {useDispatch, useSelector} from "react-redux";
import dive from "../../../utils/dive";
import {getCustomer, getMessages} from "../../../store/customer/constants/actionConstants";
import {
    CDataTable,
    CHeader,
    CRow, CCol, CCard, CCardHeader, CCardBody, CButton,
} from '@coreui/react'
import {withRouter} from "react-router-dom";
import EditManager from "../../admin/manager/editmodal";
import AddMessage from "./addmessage";
const MessageLogPage = (props) => {
    const dispatch = useDispatch();
    const fields = [
        { key: "author",
          label: "Менеджер",
        },
        { key: "created_at",
          label:"дата"
        },
        { key: "message",
        label:'заметка'
        } ]

    const addNote = () =>{
        setShowForm(true);
    }
    const [showForm, setShowForm] = useState(false);
    const messages  = useSelector(({customerReducer}) => customerReducer.message);
    return (<div>
        <AddMessage
            show={showForm}
            customer_name={props.customer_name}
            customer_id = {props.customer_id}
            closeevent={setShowForm}
        />

        <CCard>
            <CCardHeader>
                <CRow>
                <CCol xs="8">
                {props.customer_name}
                </CCol>
                <CCol xs="1" alig={"right"}
                 hidden = {props.customer_id === 0 }>
                    <CButton
                        alig = "right"
                        color={"info"}
                        onClick={addNote}>добавить</CButton>
                </CCol>

                </CRow>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    fields={fields}
                    items={messages}
                    striped
                    itemsPerPage={25}
                    pagination
                />
            </CCardBody>
        </CCard>
    </div>)
}

export default withRouter(MessageLogPage) ;