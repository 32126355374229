
import * as types from './actionTypes';

export const getManagerList = (name) => ({type: types.ADMIN_MANAGER_GET, name });

export const managerListSuccess = (name, payload) => ({type: types.ADMIN_MANAGER_GET_SUCCESS,name, payload})

export const adminFail = (name, error) => ({ type: types.ADMIN_FAIL, name, error });

export const createManager = (name,payload) => ({type: types.ADMIN_MANAGER_CREATE, name,payload});

export const updateManager = (name,payload) => ({type: types.ADMIN_MANAGER_UPDATE, name,payload});

export const updateSuccessManager = (name,payload) => ({type: types.ADMIN_MANAGER_GET_SUCCESS, name,payload});

export const getExcludeString = () => ({type: types.ADMIN_EXCLUDE_STRING_GET});

export const getExcludeStringSuccess = (payload) => ({type: types.ADMIN_EXCLUDE_STRING_GET_SUCCESS, payload })

export const saveExcludeString = (params) =>  ({type: types.ADMIN_EXCLUDE_STRING_SAVE, params });

export const deleteExcludeString = (params) =>  ({type: types.ADMIN_EXCLUDE_STRING_DELETE, params });