import { all } from 'redux-saga/effects';
import { adminUserWatcher  } from './managerSaga';
import {createUserWatcher} from "./createUser";
import {adminExcludeStringSaveWatcher, adminExcludeStringWatcher} from "./excludestring";

function* adminManagerSaga() {
    yield all([
        adminUserWatcher(),
        createUserWatcher(),
        adminExcludeStringWatcher(),
        adminExcludeStringSaveWatcher(),
    ]);
}

export default adminManagerSaga;
