export const MANAGER_GET = '[MANAGER] GET';
export const MANAGER_SUCCESS = '[MANAGER] SUCCESS';
export const MANAGER_FAIL = '[MANAGER] FAIL';
export const MANAGER_STAT_GET = '[MANAGER_STAT] GET';
export const MANAGER_STAT_SUCCESS = '[MANAGER_STAT] SUCCESS';
export const MANAGER_STAT_FAIL = '[MANAGER_STAT] FAIL';
export const MANAGER_STAT_REFRESH = '[MANAGER_STAT] REFRESH';

export const MANAGER_ORDER_GET = '[MANAGER_ORDER] GET';
export const MANAGER_ORDER_SUCCESS = '[MANAGER_ORDER] SUCCESS';
export const MANAGER_ORDER_DETAIL_GET = '[MANAGER_ORDER_DETAIL] GET';
export const MANAGER_ORDER_DETAIL_SUCCESS = '[MANAGER_ORDER_DETAIL] SUCCESS';

export const MANAGER_SALE_COMPARE_GET = '[MANAGER_SALE_COMPARE] GET';
export const MANAGER_SALE_COMPARE_SUCCESS = '[MANAGER_SALE_COMPARE] SUCCESS';
