import {
    takeEvery, put, call, select,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {ORDER_LIST_GET } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';

export function* orderListWatcher() {
    yield takeEvery(ORDER_LIST_GET, orderListWorker);
}

function* orderListWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/orders/list/${action.orderType}`, 'get',action.params);
        if ( payload.data === null ){
            yield put(actions.getOrdersSuccess( action.orderType,[]));
        } else {
            yield put(actions.getOrdersSuccess( action.orderType, payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.getOrderFail( error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}