import {
    takeEvery, put, call, select,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {COST_LIST_GET } from '../constants/actionTypes';
import myAxios from '../../../constants/myAxios';

export function* costListWatcher() {
    yield takeEvery(COST_LIST_GET, costListWorker);
}

function* costListWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/finance/costs`, 'get',action.params);
        if ( payload.data === null ){
            yield put(actions.getCostsSuccess( []));
        } else {
            yield put(actions.getCostsSuccess(payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.getCostsFail( error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}