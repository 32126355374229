import { all } from 'redux-saga/effects';
import {geoParamWatcher, geoRegionWatcher, geoTotalWatcher} from './geoSaga';

function* geoSaga() {
    yield all([
        geoTotalWatcher(),
        geoParamWatcher(),
        geoRegionWatcher(),
    ]);
}

export default geoSaga;
