import {
    takeEvery, put, call, select,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {INVENTORY_LIST_GET, INVENTORY_LOAD, INVENTORY_SAVE, INVENTORY_SAVE_SUCCESS} from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';

export function* inventoryWatcher() {
    yield takeEvery(INVENTORY_LIST_GET, inventoryWorker);
}

function* inventoryWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/inventory/supplier/upload`, 'get',action.params);
        if ( payload.data === null ){
            yield put(actions.getInventorySuccess( []));
        } else {
            yield put(actions.getInventorySuccess( payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.getInventoryFail( error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}


export function* inventorySaveWatcher() {
    yield takeEvery(INVENTORY_SAVE, inventorySaveWorker);
}
const getInventoryList = (state) => state.inventoryReducer.inventories;
function* inventorySaveWorker(action) {

        const inventories  = yield select(getInventoryList);
        try {
            yield put(startLoading());
            const payload = yield call(myAxios, `/inventory/supplier/update`, 'post',action.params);

            const ndx = inventories.findIndex( obj => obj.id === action.params.id)
            inventories[ndx] = action.params
               yield put(actions.getInventorySuccess([...inventories]));
            yield put(stopLoading());
        } catch (error) {
            let message = '';
            error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
            yield put(actions.getInventoryFail( error.response));
            yield put(showMessage({
                message,
                variant: 'error',
            }));
            yield put(stopLoading());
        }
    }



export function* inventoryLoadWatcher() {
    yield takeEvery(INVENTORY_LOAD, inventoryLoadWorker);
}

function* inventoryLoadWorker(action) {

    try {
        yield put(startLoading());
        console.log(` params `)
        console.log(action.payload)
        const payload = yield call(myAxios, `/inventory/supplier/load`, 'post',action.payload);

        if ( payload.data === null ){
            yield put(actions.getInventorySuccess( []));
        } else {
            yield put(actions.getInventorySuccess( payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.getInventoryFail( error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
