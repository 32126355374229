import {call, put, takeEvery} from "redux-saga/effects";
import {DB_CUSTOMER_GET, DB_CUSTOMER_SAVE, MESSAGES_GET} from "../constants/actionTypes";
import {showMessage, startLoading, stopLoading} from "../../theme/constants/actionConstants";
import myAxios from "../../constants/myAxios";
import * as actions from "../constants/actionConstants";


export function* getDbCustomerWatcher() {
    yield takeEvery(DB_CUSTOMER_GET, getDbCustomerWorker);
}

export function* saveDbCustomerWatcher() {
    yield takeEvery(DB_CUSTOMER_SAVE, saveCustomerWorker);
}

function* getDbCustomerWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/customer/detail/${action.params}`, 'get');
        if (payload.data === null) {
            yield put(actions.dbCustomerSuccess(null));
        } else {
            let dbCustomer = payload.data.data;
            if ( ! dbCustomer.parameters ){
                dbCustomer.parameters = {};
            }
            yield put(actions.dbCustomerSuccess(payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.customerFail('message', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}

function* saveCustomerWorker(action){
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/customer/detail/${action.id}`,
            'post',
            action.params);
        if (payload.data === null) {
            yield put(actions.dbCustomerSuccess(null));
        } else {
            yield put(actions.dbCustomerSaveSuccess(payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.customerFail('message', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}