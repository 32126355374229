
import * as types from './actionTypes';

export const geoGetTotal = (name,params) => ({type: types.GEO_TOTAL_GET, name, params });

export const geoTotalSuccess = (name, payload) => ({type: types.GEO_TOTAL_SUCCESS,name, payload})

export const geoTotalFailed = (name, payload) => ({type: types.GEO_TOTAL_FAILED,name, payload})

export const geoParamGet = (name,params) =>({type: types.GEO_PARAM_GET, name, params}) ;

export const geoParamSuccess = (name,params) =>({type: types.GEO_PARAM_SUCCESS, name, params}) ;

export const geoParamUpdate = (name, params) =>({type: types.GEO_PARAM_UPDATE, name, params}) ;

export const getRegions = () => ({type: types.GEO_REGION_GET });

export const geoRegionSuccess = (payload) => ({type : types.GEO_REGION_SUCCESS, payload})