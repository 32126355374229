
import * as types from '../constants/actionTypes';

const initialState = {
    customers :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GEO_TOTAL_SUCCESS: {
            return {
                ...state,
                [action.name]: action.payload,
                message: [],
            };
        }
        case types.GEO_PARAM_SUCCESS: {
            return {
                ...state,
                [action.name]: action.payload.geo_records,
                legend: action.payload.legend
            }
        }
        case types.GEO_REGION_SUCCESS:{
            return {
                ...state,
                regions: action.payload
            }
        }
        default: return state;
    }
};
