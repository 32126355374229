import { all } from 'redux-saga/effects';
import { signInWatcher,signOutWatcher } from './signInSaga';
import {refreshTokenWatcher} from "./refresh";

function* authSaga() {
  yield all([
    signInWatcher(),
    signOutWatcher(),
    refreshTokenWatcher(),
  ]);
}

export default authSaga;
