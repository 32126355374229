import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getCustomer, getMessages, getDbCustomer } from "../../store/customer/constants/actionConstants";
import {
    CDataTable,
    CBadge,
    CRow, CCol, CCard, CCardHeader, CCardBody, CButton, CLabel, CInput
} from '@coreui/react'
import { useHistory } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import MessageLogPage from "./message_log";
import {getManager} from "../../store/manager/constants/actionConstants";
import {downloadExcel} from "../../utils/func";
import 'antd/dist/antd.css';
import { Form, Tooltip, Button, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from "axios";
import CIcon from "@coreui/icons-react";
import CustomerCard from "./customerCard";
import {BranchSelect} from "../../components/widgets";

const { Option } = Select;
const CustomerPage = () => {
    const dispatch = useDispatch();
    const [customerName, setCustomerName] = useState("");
    const [customerId, setCustomerId] = useState(0);
    const [customer, setCustomer] = useState({});
    const [openDetail, setOpenDetail] = useState(false);
    const fields = [
        {
            key: 'name',
            label: 'Клиент',
            sorter: true,
            filter: true
        },
        {
            key: 'massages_all',
            label: 'всего',
        },
        {
            key: 'messages_new',
            label: 'новые',
        },
        {
            key: 'last_order_date',
            label: 'последний заказ',
        },
        {
            key: 'service',
            label: '*',
        },
        ]
    const auth = useSelector(({authReducer}) => authReducer.auth)
    const customers  = useSelector(({customerReducer}) => customerReducer.customers);
    const managers = useSelector( ({managerReducer}) => managerReducer.managers);
    const dbCustomer = useSelector(({customerReducer}) => customerReducer.db_customer);
    const dbCustomerAction = useSelector(({customerReducer}) => customerReducer.db_customer_action);
    const [selectedOption, setSelectedOption] = useState(null);
    const [lastOrderDay, setLastOrderDay] = useState(10);
    const [branch, setBranch] = useState(2254);
    const [excelURL, setURL] = useState('');
    const history = useHistory()
    if (  !auth || !auth.auth_token  ){
        history.push("/sigin")
    }
    useEffect(() => {
        if ( auth && auth.auth_token  ){
            dispatch(getManager('managers'))
            if ( auth.branch !== 0 ) {
                setBranch(auth.branch)
            }
        }
    }, []);

    useEffect(() => {
        // action on update of movies
        refreshData()
    }, [selectedOption]);
    useEffect(() => {
        if (dbCustomerAction === "LOAD" && dbCustomer !== undefined ) {
            setOpenDetail(true);
        }else {
            setOpenDetail(false);
        }
    },[dbCustomerAction])
    useEffect(() => {
        if (!excelURL){
            return ;
        }
        axios({
            url: `/api/custpub/download/${excelURL}`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', excelURL); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    },[excelURL])
    const refreshData = () => {
        if (auth.auth_token) {
            dispatch(getCustomer('customers', initParam()));
            setCustomerId(0);
            setCustomerName("");
        }
    }
    const initParam = () => {
        let param = {};
        if (selectedOption !== null){
            param["manager_id"] = selectedOption;
        }
        if ( lastOrderDay !== 0 ){
            param["last_order_day"] = Number(lastOrderDay);
        }
            param["branch"] = branch
        return param;
    }
    const getBadge = status => {
        switch (status) {
            case 'active': return ''
            case 'old': return 'danger'
            default: return 'primary'
        }
    }
    const editCustomer = (item) =>{
        if (item.id) {
            dispatch(getDbCustomer(item.id))
            setCustomer(item)
        }
    }
    const total = customers.length
    const totalNotUsed = customers.filter( item => item.status === 'old').length
    const download = () => {
        downloadExcel(initParam(),setURL)
    }

    return (
        <>
            <CustomerCard
                customer = {customer}
                enable_form = {openDetail}
                close_func = {setOpenDetail}
                db_customer = {dbCustomer}
            />
            <CRow>
                <CCol xs="12" sm="4">
                    <Form name="control-hooks" >
                        <Form.Item
                            name="gender"
                            label="Менеджер">
                    <Select
                        onChange={(v) => {
                            setSelectedOption(v)
                        }}
                        allowClear >
                        { managers.map((e, key) => {
                            return <Option key = {key} value={e.value}>{e.label}</Option>;
                            }
                        )}
                        </Select>
                        </Form.Item>
                    </Form>

                </CCol>
                <CCol md="2">
                    <CLabel htmlFor="date-input">дней без активности</CLabel>
                </CCol>
                <CCol xs="4" md="2">
                    <CInput type="number" id="date-input"
                            name="date-input"
                            value={lastOrderDay}
                            onChange={(event) => {
                                setLastOrderDay(event.target.value)
                            }}
                    />
                </CCol>
                <CCol>
                    <BranchSelect
                        value = {branch}
                        onChange = {setBranch}/>
                </CCol>
                <CCol>
                    <Button type="primary"
                            icon={<SearchOutlined />}
                            onClick={refreshData }
                    >
                        Обновить
                    </Button>
                </CCol>
            </CRow>
        <CRow>
        <CCol xs="12" lg="8">
            <CCard>
                <CCardHeader>
                    <CRow>
                    <CCol xs="10"><h6>
                        {`Клиенты  Всего ${total} неактивных ${totalNotUsed} `}
                    </h6>
                    </CCol>
                    <CCol xs="1" alig={"right"}>
                        <CButton
                            alig = "right"
                            color={"info"}
                            onClick={download}>xls</CButton>
                    </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
            <CDataTable
                items={customers}
                fields={fields}
                columnFilter
                striped
                itemsPerPage={8}
                pagination
                onRowClick={ (item) => {
                    setCustomerName(item.name);
                    setCustomerId(item.id)
                    dispatch(getMessages('messages',item.id));
                }}
                scopedSlots = {{
                    'name':
                        (item)=>(
                            <td>
                                <CBadge color={getBadge(item.status)}>
                                    {item.name}
                                </CBadge>
                            </td>
                        ),
                    'massages_all':
                        (item) => (
                            <td color={'secondary'}> {item.massages_all}</td>
                        ),
                    'messages_new':
                        (item) => (
                            <td> {item.messages_new}</td>
                        ),
                    'last_order_date':
                        (item) => (
                            <td> {item.last_order_date}</td>
                        ),
                    'service':
                    (item)=>(
                            <td>
                            <CButton color="info" variant='outline'
                            onClick={()=>{
                           editCustomer(item);
                        }
                           }>
                    <CIcon name="cil-medical-cross" />*
                    </CButton>
                    </td>
                    )
                }}

                />
                </CCardBody>
            </CCard>
        </CCol>
        <CCol xs="12" lg="4" >
            <MessageLogPage
            customer_name = {customerName}
            customer_id = {customerId}/>
        </CCol>
        </CRow>
        </>
    )
}
export default withRouter( CustomerPage) ;