import React from 'react';
import { Route, Switch, } from 'react-router-dom';
import VerifiedRoute from './privateRouting/verifiedRoute';
import LoginPage from '../pages/login/index';
import MainPage from "../pages/main";
import CustomerPage from "../pages/customers";
import {TheLayout} from "../pages/containers";


const Router = () => (
  <>
    <Switch>
      <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
      <Route path="/signin" component={LoginPage} />
      {/*<VerifiedRoute fallBack='/' path="/dashboard/main" exact component={Dashboard} />*/}
      <Route component = {() => <div>not found</div>} />
    </Switch>

    {/*<ShowMessage />*/}
  </>
);

export default Router;