import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import {showError, showMessage, startLoading, stopLoading} from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {GEO_PARAM_GET, GEO_REGION_GET, GEO_TOTAL_GET, GEO_TOTAL_SUCCESS} from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';

export function* geoTotalWatcher() {
    yield takeEvery(GEO_TOTAL_GET, geoTotalWorker);
}
export  function* geoParamWatcher() {
    yield takeEvery(GEO_PARAM_GET, geoParamWorker) ;
}
export  function* geoRegionWatcher() {
    yield takeEvery(GEO_REGION_GET, geoRegionWorker) ;
}

function* geoTotalWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/geo/', 'get', action.params);
        let result = [];
        if ( payload.data.data !== null ){
            result = payload.data.data;
        }
        yield put(actions.geoTotalSuccess('geo_total', result));
        yield put(stopLoading());
        yield put(showError("load complete"));
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.geoTotalFailed('customers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}


function* geoParamWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/geo/params/', 'get', action.params);
        let result = [];
        if ( payload.data.data !== null ){
            result = payload.data.data;
        }
        yield put(actions.geoParamSuccess('geo_params', payload.data));
        yield put(stopLoading());
        yield put(showError("load complete"));
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.geoTotalFailed('geo_params', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}

function* geoRegionWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/geo/regions/', 'get', action.params);
        let result = [];
        if ( payload.data.data !== null ){
            result = payload.data.data;
        }
        yield put(actions.geoRegionSuccess( result));
        yield put(stopLoading());
        yield put(showError("load complete"));
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.geoTotalFailed('customers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}