import React, {useEffect, useState} from "react";
import {Button,Form,Input, Modal} from "antd";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {dbCustomerClose, saveDbCustomer} from "../../../store/customer/constants/actionConstants";

const CustomerCard = (props) => {
    const dispatch = useDispatch();
    const isModalVisible = props.enable_form
    const setIsModalVisible  = props.close_func
    const customer = props.customer
    let dbCustomer = props.db_customer
    if (dbCustomer === null ){
        dbCustomer = {}
    }
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        dbCustomer.email = form.getFieldValue("email");
        dbCustomer.phone = form.getFieldValue("phone");
        dbCustomer.parameters.instagram = form.getFieldValue("instagram");
        if (!dbCustomer.id ){
            dbCustomer.id = 0 ;
        }
        dispatch(saveDbCustomer(dbCustomer.id, dbCustomer));

       // setIsModalVisible(false);
    };

    const handleCancel = () => {
        dispatch(dbCustomerClose())
    };
    const [form] = Form.useForm();
    useEffect(()=> {
        if (dbCustomer && customer ) {
            if (!dbCustomer.phone) {
                dbCustomer.phone = customer.phone;
            }
            form.setFieldsValue({
                phone: dbCustomer.phone,
                email: dbCustomer.email,
                instagram: dbCustomer.parameters.instagram,
            })
        }
    },[dbCustomer,customer])


    return (
        <Modal title={customer.name}
               visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
        destroyOnClose={true}
        >
            <Form {...layout} form={form} name="control-hooks" >
                <Form.Item name="phone" label="Телефон" rules={[{ required: false }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="e-Mail" rules={[{ required: false }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="instagram" label="instagram" rules={[{ required: false }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default withRouter(CustomerCard) ;