import { all } from 'redux-saga/effects';
import authSaga from '../authorization/sagas';
import customerSaga from "../customer/sagas";
import managerSaga from "../manager/sagas";
import adminManagerSaga from "../admin/sagas";
import geoSaga from "../geo/sagas"
import messageLogSage from "../messages/sagas"
import inventorySaga from "../inventory/sagas";
import ordersSaga from "../orders/sagas";
import financeCostsSaga from "../finance/cost/sagas";
function* rootSaga() {
    yield all([
        authSaga(),
        customerSaga(),
        managerSaga(),
        adminManagerSaga(),
        geoSaga(),
        messageLogSage(),
        inventorySaga(),
        ordersSaga(),
        financeCostsSaga(),
    ]);
}

export default rootSaga;
