import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {AUTH_SIGNIN, AUTH_SIGNOUT} from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';
import history from "../../../routing/history";


export function* signInWatcher() {
  yield takeEvery(AUTH_SIGNIN, signInWorker);
}

export function* signOutWatcher() {
  yield takeEvery(AUTH_SIGNOUT, signOutWorker);
}

function* signInWorker(action) {
  yield put(startLoading());
  try {
    const payload = yield call(myAxios, '/auth/login', 'post', action.params);
    yield put(actions.authSuccess('auth', payload.data.data));
    yield put(stopLoading());
    yield put(showMessage({
      message: 'You logged in',
      variant: 'success',
    }));
  } catch (error) {
    let message = '';
    error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
    yield put(actions.authFail('auth', error.response));
    yield put(showMessage({
      message,
      variant: 'error',
    }));
    yield put(stopLoading());
  }
}


function* signOutWorker(action) {
  yield put(actions.signOutSuccess('auth'));
  yield put(push("/signin"));
}

