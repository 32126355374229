import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { message } from 'antd';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading = () => {
    const isLoading = useSelector(({ themeReducer }) => themeReducer.isLoading);
    return (
        <Spin
            size = {"large"}
            spinning = {isLoading}
            indicator={antIcon} />
    );
};
export default Loading;