


export function processManagerStat( payload , displayType ) {
   let result = {}
    let headerRes = [
        {
            key: "name",
            label: "Менеджер"
        }
    ]
    let header = { }
    let customer = {}
    payload.forEach( item => {
        customer = result[item.id];
        if ( customer === undefined ){
            customer = {
                "id" : item.id,
                "name" :item.name,
            }
            customer[item.date_range] = item[displayType];
        } else {
            customer[item.date_range] = item[displayType];
        }
        header[item.date_range] =`${item.date_range}`
        result[customer.id] = customer;
    })
    Object.keys(header).map((key) => {
        return {
            key: key,
            label: header[key]
        }
    })


    return {header:
            headerRes.concat(
            Object.keys(header).map((key) => {
            return {
                key: key,
                label: header[key]
            }
        }))
        , data: Object.keys(result).map(key=> result[key]) };
}

