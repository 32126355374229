import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav =  [
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'История клиентов',
  //   to: '/customer',
  //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   }
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Login',
    to: '/signin',
    icon: 'cil-drop',
  },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Components'],
  //   signed :true
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'История клиентов',
    to: '/customer',
    signed :true
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'История сообщений',
    to: '/message_log',
    signed :true
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Статистика по менеджерам',
    to: '/manager',
    signed :true,
    role : 'admin'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Заказы менеджеров',
    to: '/manager-order',
    signed :true,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Сравнение продаж',
    to: '/compare-sales',
    signed :true,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Статистика по регионам',
    to: '/manager-region',
    signed :true,
    role : 'admin'
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Админ',
    route: '/base',
    icon: 'cil-puzzle',
    signed :true,
    role : 'admin',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Менгеджеры',
        to: '/admin/managers',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Регионы',
        to: '/admin/regions',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Загрузка товаров',
        to: '/admin/supplier/upload',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Настройка загрузки товаров',
        to: '/admin/settings/import',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Затраты',
        to: '/finance/costs',
      },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Документы',
    route: '/base',
    icon: 'cil-puzzle',
    signed :true,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Заказы клиентов',
        to: '/orders/customer_order',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Расхдные накладные',
        to: '/orders/sales_invoice',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Заказы поставщиков',
        to: '/orders/supplier_order',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Приходы товаров',
        to: '/orders/incoming_invoice',
      },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Проверка цен',
    route: '/base',
    icon: 'cil-puzzle',
    signed :true,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Заказы клиентов',
        to: '/checkprice/customer_order',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Расхдные накладные',
        to: '/checkprice/sales_invoice',
      },
    ]
  },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Forms',
  //       to: '/base/forms',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Jumbotron',
  //       to: '/base/jumbotrons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navs',
  //       to: '/base/navs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navbars',
  //       to: '/base/navbars',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Progress',
  //       to: '/base/progress-bar',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Switches',
  //       to: '/base/switches',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tabs',
  //       to: '/base/tabs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Buttons',
    route: '/buttons',
    icon: 'cil-cursor',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Buttons',
        to: '/buttons/buttons',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Brand buttons',
        to: '/buttons/brand-buttons',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Buttons groups',
        to: '/buttons/button-groups',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Dropdowns',
        to: '/buttons/button-dropdowns',
      }
    ],
  }
]

export default _nav
