
import * as types from './actionTypes';

export const getManager = (name) => ({type: types.MANAGER_GET, name });

export const managerSuccess = (name, payload) => ({type: types.MANAGER_SUCCESS,name, payload})

export const managerFail = (name, error) => ({ type: types.MANAGER_FAIL, name, error });

export const getManagerStat = (name,payload, date_range_type ) => ({type: types.MANAGER_STAT_GET, name,payload, date_range_type });

export const managerStatSuccess = (name, payload) => ({type: types.MANAGER_STAT_SUCCESS,name, payload})

export const managerStatFail = (name, error) => ({ type: types.MANAGER_STAT_FAIL, name, error });

export const managerStatRefresh = (date_range_type) => ({ type: types.MANAGER_STAT_REFRESH, date_range_type});

export const getManagerOrder = (name,payload) => ({type: types.MANAGER_ORDER_GET, name,payload });

export const managerOrderSuccess = (name, payload) => ({type: types.MANAGER_ORDER_SUCCESS,name, payload})

export const getManagerSaleCompare = (name, payload) => ({type: types.MANAGER_SALE_COMPARE_GET, name, payload});

export const managerSaleCompareSuccess = (name, payload) => ({type: types.MANAGER_SALE_COMPARE_SUCCESS, name, payload});