import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { notification } from 'antd';

const Error = () => {
    let error = useSelector(({ themeReducer }) => themeReducer.error);
    if ( !error ){
       error = {
           hasError : false ,
           description : "",
       }
    }
    const openNotificationWithIcon = type => {
        notification[type]({
            message: 'Ошибка',
            description:
                error.description,
        });
    };

    useEffect(() => {
        if (error.hasError ){
            openNotificationWithIcon('error')
        }
    },[error]);

    return (
        <div/>
    );
};
export default Error;