import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { MANAGER_GET } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';


export function* managerWatcher() {
    yield takeEvery(MANAGER_GET, managerWorker);
}

function* managerWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/manager/', 'get');
        yield put(actions.managerSuccess('managers', payload.data.data));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.managerFail('managers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
