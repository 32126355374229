// @ts-ignore
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { PrivateRouting } from '../interfaces';
import { useSelector } from 'react-redux';

const Verified = (props: PrivateRouting) => {
  return (
      <Route {...props}
          component = {(newProps: any) => {
            const PageComponent: any = props.component;
            if (props.path === "ok") {
              return (
            <PageComponent {...newProps}/>
              );
            }
            return (
            <Redirect to ={props.fallBack} />
            );
          }}
      />
  )
};

export default Verified;