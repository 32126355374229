import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { MESSAGES_GET } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';


export function* messageWatcher() {
    yield takeEvery(MESSAGES_GET, messageWorker);
}

function* messageWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/customer/message/${action.customer_id}`, 'get');
        if ( payload.data === null ){
            yield put(actions.messagesSuccess('message', []));
        } else {
            yield put(actions.messagesSuccess('message', payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.customerFail('message', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
