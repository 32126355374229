
import * as types from '../constants/actionTypes';
import {processManagerStat} from "./funcs";

const initialState = {
    managers :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.MANAGER_SUCCESS: {
            let managers = [];
            action.payload.managers.forEach(element => {
                managers.push({
                    value: element.id ,
                    label: element.name
                })
            })
            return {
                ...state,
                [action.name]: managers,
            };
        }
        case types.MANAGER_FAIL: {
            return {
                ...state,
                [action.name]: {
                    error: action.error,
                },
            };
        }
        case types.MANAGER_STAT_SUCCESS: {
            return {
                ...state,
                [action.name]: action.payload.response,
                source: action.payload.source
            };
        }
        case types.MANAGER_STAT_FAIL: {
            return {
                ...state,
                [action.name]: {
                    error: action.error,
                },
            };
        }
        case types.MANAGER_ORDER_SUCCESS:{
            console.log( action.payload)
            console.log("types.MANAGER_ORDER_SUCCESS")
            return {
                ...state,
                manager_shipment_total: action.payload,

            }
        }
        case types.MANAGER_SALE_COMPARE_SUCCESS:{
            return {
                ...state,
                manager_compare_sale: action.payload,
            }
        }
        default: return state;
    }
};
