
import * as types from '../constants/actionTypes';

const initialState = {
    managers :[],
    exclude_string :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_MANAGER_GET_SUCCESS: {
            return {
                ...state,
                [action.name]: {
                    users: action.payload,
                }
            };
        }
        case types.ADMIN_FAIL: {
            return {
                ...state,
                [action.name]: {
                    error: action.error.data,
                },
            };
        }
        case types.ADMIN_MANAGER_UPDATE_SUCCESS: {
            return {
                ...state,
                [action.name]: {
                    users: action.payload,
                }
            };
        }
        case types.ADMIN_EXCLUDE_STRING_GET_SUCCESS:
            return {
                ...state,
                "exclude_string": action.payload,
            }
        default: return state;
    }
};
