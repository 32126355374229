import { combineReducers } from 'redux';
import authReducer from '../authorization/reducer';
import customerReducer from '../customer/reducer';
import managerReducer from '../manager/reducer';
import adminReducer  from '../admin/reducer';
import themeReducer from '../theme/reducer';
import geoReducer from '../geo/reducer';
import messagesReducer from '../messages/reducer';
import inventoryReducer from '../inventory/reducer';
import ordersReducer from '../orders/reducer';
import financeCostReducer from '../finance/cost/reducer';

export default combineReducers({
  themeReducer,
  authReducer,
  customerReducer,
  managerReducer,
  adminReducer,
  geoReducer,
  messagesReducer,
  inventoryReducer,
  ordersReducer,
  financeCostReducer,
});
