import {select,
    takeEvery, put, call,
} from 'redux-saga/effects';
import {ADMIN_EXCLUDE_STRING_GET} from "../constants/actionTypes";
import {showMessage, startLoading, stopLoading} from "../../theme/constants/actionConstants";
import myAxios from "../../constants/myAxios";
import * as actions from "../constants/actionConstants";
import {getExcludeStringSuccess} from "../constants/actionConstants";
import {INVENTORY_SAVE} from "../../inventory/constants/actionTypes";


export function* adminExcludeStringWatcher() {
    yield takeEvery(ADMIN_EXCLUDE_STRING_GET, adminExcludeStringWorker);
}

function* adminExcludeStringWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/exclude_string/', 'get');
        yield put(actions.getExcludeStringSuccess(payload.data.data));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.adminFail('managers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}

export function* adminExcludeStringSaveWatcher() {
    yield takeEvery(INVENTORY_SAVE, adminExcludeStringSaveWorker);
}

function* adminExcludeStringSaveWorker(action) {
    try {
        yield put(startLoading());
        const payload = yield call(myAxios, `/exclude_string`, 'post',action.params);

        yield put(actions.getExcludeStringSuccess(payload.data.data));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.adminFail( error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}

