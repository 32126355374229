import myAxios, {download} from '../store/constants/myAxios';
import { saveAs } from 'file-saver';
import * as base64 from "entities";
import axios from "axios";

export const downloadExcel = (params, setUrl ) =>{
    const FileDownload = require('js-file-download');
    download('/customer/download', 'post', params).
    then(function (response) {
        setUrl(response.data.data)
    });
}

export const downloadCommonExcel = (url, method, params, setUrl ) =>{
    console.log('11111111111111111')
    console.log(params)
    const FileDownload = require('js-file-download');
    download(url , method, params).
    then(function (response) {
        setUrl(response.data.data)
    });
}

export const downloadFile = (excelURL ) => {
    axios({
        url: `/api/common/download/${excelURL}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', excelURL); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}