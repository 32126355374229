
import * as types from '../constants/actionTypes';

const initialState = {
    message_log :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.MESSAGE_LOG_SUCCESS: {
            return {
                ...state,
                message_log: action.payload,
            };
        }
        case types.MESSAGE_LOG_FAIL: {
            return {
                ...state,
                message_log: [],
                errors : {
                    error: action.error,
                },
            };
        }
        default: return state;
    }
};
