import { all } from 'redux-saga/effects';
import { customerWatcher  } from './customerSaga';
import {messageWatcher} from "./messageSaga";
import {messageAddWatcher} from "./addMassageSaga";
import {getDbCustomerWatcher, saveDbCustomerWatcher} from "./dbcustomer";

function* customerSaga() {
    yield all([
        customerWatcher(),
        messageWatcher(),
        messageAddWatcher(),
        getDbCustomerWatcher(),
        saveDbCustomerWatcher(),
    ]);
}

export default customerSaga;
