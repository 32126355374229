import React, {useEffect, useState} from 'react'
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CForm,CInputGroup,CInputGroupPrepend,
    CFormGroup, CInput, CInputGroupAppend, CInputGroupText,
    CInvalidFeedback
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {createManager} from "../../../store/admin/constants/actionConstants";
import {useDispatch} from "react-redux";


const EditManager = (props) =>{
    const dispatch = useDispatch();
    let user = {
        login:"",
    };
    if (props.user && props.user.user){
        user = props.user.user
    }

    const [login,setLogin] = useState(user.login);
    const [password,setPassword]= useState("");
    const [confirmPassword,setConfirmPassword]= useState("");

    const saveUser = () => {
        const payload = {
            user_id :user.id,
            login: login,
            manager_id : props.user.id,
            branch : props.user.branch,
            password :password
        };
        dispatch(createManager("managers",payload));
        closeModal();
    }
    useEffect(()=>{
        if (props.show) {
            setLogin(user.login)
        }
    },[props.show]);

    const closeModal = () =>{
      setLogin("");
      setPassword("");
      setConfirmPassword("");
      props.closeevent(false);
    };

    return(
        <CModal
            show={props.show}
            onClose={closeModal}
            closeOnBackdrop={false}
        >
            <CModalHeader closeButton>
                <CModalTitle>{props.user&&props.user.name}</CModalTitle>
            </CModalHeader>
            <CModalBody>
            <CForm action="" method="post">
                <CFormGroup>
                    <CInputGroup>
                        <CInputGroupPrepend>
                            <CInputGroupText>Логин</CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput id="login" name="login"
                            value={login}
                             disabled={user.id}
                            onChange={(e)=> {setLogin(e.target.value)}}/>
                        <CInputGroupAppend>
                            <CInputGroupText><CIcon name="cil-user" /></CInputGroupText>
                        </CInputGroupAppend>
                    </CInputGroup>
                </CFormGroup>
                <CFormGroup>
                    <CInputGroup>
                        <CInputGroupPrepend>
                            <CInputGroupText>Пароль</CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" id="password3" name="password3"
                                value={password}
                                invalid = {password !== confirmPassword}
                                isValid = {false}
                                onChange={(e)=> {setPassword(e.target.value)}}
                        />
                        <CInputGroupAppend>
                            <CInputGroupText><CIcon name="cil-asterisk" /></CInputGroupText>
                        </CInputGroupAppend>
                        <CInvalidFeedback>Пароль  должен совпадать</CInvalidFeedback>
                    </CInputGroup>
                </CFormGroup>
                <CFormGroup>
                    <CInputGroup>
                        <CInputGroupPrepend>
                            <CInputGroupText>Проверка пароля</CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" id="confirm-password" name="confirm-password3"
                                value={confirmPassword}
                                onChange={(e)=> {setConfirmPassword(e.target.value)}}
                        />
                        <CInputGroupAppend>
                            <CInputGroupText><CIcon name="cil-asterisk" /></CInputGroupText>
                        </CInputGroupAppend>
                    </CInputGroup>

                </CFormGroup>
            </CForm>

            </CModalBody>
            <CModalFooter>
                <CButton color="primary"
                         disabled = { password === "" || password !== confirmPassword}
                         onClick={saveUser} >Сохранить</CButton>{' '}
                <CButton
                    color="secondary"
                    onClick={() => {
                        closeModal();
                    }}
                >Cancel</CButton>
            </CModalFooter>
        </CModal>

    )
}
export default EditManager;