import {select,
    takeEvery, put, call,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import {ADMIN_MANAGER_CREATE, ADMIN_MANAGER_GET} from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';
//import {select} from "@redux-saga/core/types/effects";


export function* createUserWatcher() {
    yield takeEvery(ADMIN_MANAGER_CREATE, createUserWorker);
}
const getUserList = (state) => state.adminReducer.managers
function* createUserWorker(action) {
    yield put(startLoading());
    let users = [];
    try {
        const managers = yield select(getUserList);
        if (managers.users) {
            users = managers.users
        }
    } catch (error){
        console.log(error)
    }
    try {
        let payload
        if ( action.payload. user_id ){
            payload = yield call(myAxios, `/users/${action.payload.user_id}`, 'put',action.payload);
        } else {
            payload = yield call(myAxios, '/users', 'post',action.payload);
        }
         let index = users.findIndex( item => item.id === payload.data.data.manager_id )
        if ( index !== -1 ){
            users[index].user = payload.data.data;
        }
        yield put(actions.updateSuccessManager('managers', users ));
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.adminFail('managers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
