
import * as types from './actionTypes';

export const authSignIn = (name, params) => ({ type: types.AUTH_SIGNIN, name, params });

export const authSuccess = (name, payload) => ({ type: types.AUTH_SUCCESS, name, payload });

export const authFail = (name, error) => ({ type: types.AUTH_FAIL, name, error });

export const actionToken = (token) => ({ type: types.AUTH_TOKEN, token });

export const authSignOut = (name) => ({ type: types.AUTH_SIGNOUT, name });

export const signOutSuccess = (name) => ({ type: types.SIGNOUT_SUCCESS, name});