
import * as types from '../constants/actionTypes';

const initialState = {
    costs :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.COST_LIST_SUCCESS: {
            console.log(action.payload)
            return {
                ...state,
                costs: action.payload,
            };
        }
        case types.COST_LIST_FAIL: {
            return {
                ...state,
                costs: [],
                errors : {
                    error: action.error,
                },
            };
        }
        default: return state;
    }
};
