
import * as types from './actionTypes';
import {INVENTORY_LOAD} from "./actionTypes";

export const getInventory = (params) => ({type: types.INVENTORY_LIST_GET, params });

export const getInventorySuccess = (payload) => ({type: types.INVENTORY_LIST_SUCCESS, payload})

export const getInventoryFail = (error) => ({ type: types.INVENTORY_LIST_FAIL, error });

export const saveInventory = (params) =>  ({type: types.INVENTORY_SAVE, params });

export const loadInventory = (payload) =>  ({type: types.INVENTORY_LOAD, payload});

export const loadDeficit = (params) => ({type: types.DEFICIT_LOAD, params})

export const loadDeficitSucess = (payload) => ({type: types.DEFICIT_LOAD_SUCCESS, payload})