import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { MESSAGE_LOG_GET } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';

export function* messageLogWatcher() {
    yield takeEvery(MESSAGE_LOG_GET, messageLogWorker);
}

function* messageLogWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/message/logs/`, 'get',action.params);
        if ( payload.data === null ){
            yield put(actions.messageLogSuccess( []));
        } else {
            yield put(actions.messageLogSuccess( payload.data.data));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.messageLogFail( error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
