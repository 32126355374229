
export function processManagerStat( payload ) {
    let result = {}

    let header = [{
        key: "name",
        label: "Менеджер"
    }]
    let customer = {}
    payload.forEach( item => {
        customer = result[item.id];
        if ( customer === undefined ){
            customer = {
                "id" : item.id,
                "name" :item.name,
            }
            customer[item.date_range] = item.amount;
        } else {
            customer[item.date_range] = item.amount;
        }
        header[item.date_range] = `${item.date_range}`;

        result[customer.id] = customer;
    })
    return {header: header, data: Object.keys(result).map(key=> result[key]) };
}

