import { all } from 'redux-saga/effects';
import {inventorySaveWatcher, inventoryWatcher,inventoryLoadWatcher} from "./inventorySaga";

function* inventorySaga() {
    yield all([
        inventoryWatcher(),
        inventorySaveWatcher(),
        inventoryLoadWatcher(),
    ]);
}

export default inventorySaga;
