export const INVENTORY_LIST_GET = '[INVENTORY LIST] GET';
export const INVENTORY_LIST_SUCCESS = '[INVENTORY LIST] SUCCESS';
export const INVENTORY_LIST_FAIL = '[INVENTORY LIST] FAIL';

export const INVENTORY_SAVE = '[INVENTORY] SAVE';
export const INVENTORY_SAVE_SUCCESS = '[INVENTORY] SAVE SUCCESS';
export const INVENTORY_LOAD = '[INVENTORY] LOAD';


export const DEFICIT_LOAD = `[INVENTORY] DEFICIT LOAD`;
export const DEFICIT_LOAD_SUCCESS = `[INVENTORY] DEFICIT LOAD SUCCESS`;