import { all } from 'redux-saga/effects';
import {messageLogWatcher} from "./messageSaga";

function* messageLogSaga() {
    yield all([
        messageLogWatcher(),
    ]);
}

export default messageLogSaga;
