import axios from 'axios';

export default (url, method, data, upload) => {
  const token = sessionStorage.getItem("authToken");
  let headers = {
    'Content-Type': 'application/json',
  }
  if (token){
    headers["X-Token"] =  `Bearer ${token}`
  }
  if ( method === 'get') {
      return axios({
          method,
          url: `/api${url}`,
          headers: headers,
          params: data,
      })
  }
  return  axios(
      {
        method,
        url: `/api${url}`,
        headers: headers,
        data: JSON.stringify(data),
      })
};

export const download = (url, method, data, upload) => axios({
  method,
  url: `/api${url}`,
  headers: sessionStorage.authToken ? {
    'Content-Type': 'application/json',
    'X-Token': `Bearer ${sessionStorage.authToken}`,
  } : { 'Content-Type': 'application/json' },
  data: data,
});