import moment from "moment";
import React from "react";
import { Select } from 'antd';
const { Option } = Select;

const BranchSelect = (props) => {
    return (
        <Select style={{ width: 120 }} value={props.value} onChange={props.onChange}>
            <Option value={2254}>{"Женский"}</Option>
            <Option value={2253}>{"Мужской"}</Option>
        </Select>
    )
};

export default BranchSelect