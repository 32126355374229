import jwtDecode from 'jwt-decode';
import * as types from '../constants/actionTypes';
import history from "../../../routing/history";

const initialState = {
  auth: {
    auth_token: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_SUCCESS: {
      if (action.name === 'auth') sessionStorage.authToken = action.payload.auth_token;
      return {
        ...state,
        [action.name]: action.payload,
      };
    }
    case types.SIGNOUT_SUCCESS:{
      sessionStorage.setItem("authToken",undefined)
      sessionStorage.clear();
      return {
        ...state,
        [action.name]: undefined,
      };
    }
    case types.AUTH_FAIL: {
      return {
        ...state,
        [action.name]: {
          error: action.error,
        },
      };
    }
    default: return state;
  }
};
