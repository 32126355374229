import { all } from 'redux-saga/effects';
import {orderListWatcher} from "./orderSaga";
import {checkPriceWatcher} from "../check_price/sagas/checkPriceSaga";

function* ordersSaga() {
    yield all([
        orderListWatcher(),
        checkPriceWatcher(),
    ]);
}

export default ordersSaga;
