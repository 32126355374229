import {
    takeEvery, put, call,
} from 'redux-saga/effects';
import {showError, showMessage, startLoading, stopLoading} from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { CUSTOMER_GET } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';


export function* customerWatcher() {
    yield takeEvery(CUSTOMER_GET, customerWorker);
}

function* customerWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, '/customer', 'post', action.params);
        let result = [];
        if ( payload.data.data !== null ){
            result = payload.data.data;
        }
        yield put(actions.customerSuccess('customers', result));
        yield put(stopLoading());
        yield put(showError("load complete"));
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.customerFail('customers', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
