import {
    takeEvery, put, call, select,
} from 'redux-saga/effects';
import { showMessage, startLoading, stopLoading } from '../../theme/constants/actionConstants';
import * as actions from '../constants/actionConstants';
import { MESSAGE_ADD } from '../constants/actionTypes';
import myAxios from '../../constants/myAxios';


export function* messageAddWatcher() {
    yield takeEvery(MESSAGE_ADD, messageAddWorker);
}
const getMessageList = (state) => state.customerReducer.message

function* messageAddWorker(action) {
    yield put(startLoading());
    try {
        const payload = yield call(myAxios, `/message`, 'post',action.params );
        if ( payload.data !== null ){
            const messages = yield select(getMessageList);
            const newArray = [payload.data.data].concat(messages)
            yield put(actions.messagesSuccess('message', newArray));
        }
        yield put(stopLoading());
    } catch (error) {
        let message = '';
        error.response && error.response.status >= 500 ? message = 'Server error' : message = 'Invalid credantials';
        yield put(actions.customerFail('message', error.response));
        yield put(showMessage({
            message,
            variant: 'error',
        }));
        yield put(stopLoading());
    }
}
